@import "./Variables.scss";


.tvl-drop-1,
.fee-high-1 {
  color: rgba(255, 200, 200);
}

.tvl-drop-2,
.fee-high-2 {
  color: rgba(255, 150, 150);
}

.tvl-drop-3,
.fee-high-3 {
  color: rgba(255, 100, 100);
}

.tvl-drop-4,
.fee-high-4 {

  color: rgb(255, 50, 50);
}

.tvl-drop-5,
.fee-high-5 {
  color: rgba(255, 0, 0);
}

.tvl-increase-1,
.fee-low-5 {
  color: rgba($tvl-increase, 0.6);
}

.tvl-increase-2,
.fee-low-4 {
  color: rgba($tvl-increase, 0.7);
}

.tvl-increase-3,
.fee-low-3 {
  color: rgba($tvl-increase, 0.8);
}

.tvl-increase-4,
.fee-low-2 {
  color: rgba($tvl-increase, 0.9);
}

.tvl-increase-5,
.fee-low-1 {
  color: $tvl-increase;
}

.tvl-change,
.fee-color {
  opacity: $colors-mute-1;
}