.scam {
  .o-block {
    min-height: 500px;
  }

  table {
    tr {
      td {
        word-wrap: break-word;

        a {
          text-decoration: none;
        }

        .address-bottom {
          font-size: 12px;
        }
      }

      td:nth-child(1) {
        min-width: 140px;
      }

      td:nth-child(2) {
        min-width: 300px;
      }

      td:nth-child(3) {
        min-width: 120px;
      }
    }
  }
}