.changelog {
  .o-block {
    min-height: 500px;
  }

  table {
    width: 100%;

    tr {
      td {
        word-wrap: break-word;
      }
    }
  }
}