$basic-text-color: rgb(209, 209, 209);
$basic-text-color-p: rgb(185, 185, 185);
$basic-background: rgb(8, 8, 8);
$basic-purple: rgb(232, 28, 255);
$basic-blue: rgb(111, 214, 255);
$basic-blue-end: rgb(91, 127, 250);
$basic-orange: rgb(249, 158, 34);
$basic-lime: rgb(28, 255, 42);
$bg-primary: linear-gradient(to right, $basic-purple 0%, $basic-orange 100%);
$bg-secondary: linear-gradient(to right, #00f9ef 0%, $basic-blue-end 100%);
$bg-success: linear-gradient(to right, $basic-lime 0%, $basic-orange 100%);
$bg-promo: linear-gradient(to right, $basic-purple 0%, $basic-blue-end 100%);
$bg-dead: linear-gradient(to right, rgba(255, 0, 0, 0.1) 0%, rgba(255, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0) 100%);
$bg-input: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(111, 214, 255, 0.6);
$tvl-increase: rgb(173, 255, 47);

$table-panel-background-color: black;
$table-border-color: black;

$colors-mute-1: 0.9;
$colors-mute-2: 0.85;
$colors-mute-3: 0.8;
$colors-mute-4: 0.75;
$colors-mute-5: 0.7;


@mixin secondary-text-gradient() {
    background: linear-gradient(to right, #00F9EF 0%, #5B7FFA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin primary-text-gradient() {
    background: linear-gradient(to right, $basic-purple 0%, #F99E22 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

//BOOTSTRAP OVERRIDES

.bg-primary,
.bg-secondary,
.bg-success {
    color: black;
    opacity: $colors-mute-3;
}

.bg-primary {
    background: $bg-primary;
}

.bg-secondary {
    background: $bg-secondary;
}

.bg-success {
    background: $bg-success;
}

.bg-promo {
    background: $bg-promo;
    color: white;
}