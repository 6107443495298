@import "../../dist/css/Variables.scss";

.homepage {
  .main-table {
    font-size: 0.95rem;

    thead {
      tr {
        th {
          border-color: $table-border-color;
          height: 3.2em;

          &:first-child {
            display: none;
          }
        }

        div {
          margin-top: 2px;
        }
      }
    }

    tr {
      background-color: none;

      &:nth-child(odd) td {
        border-color: $table-border-color;
        height: 3.5em;
        font-family: inherit !important;
      }

      &:nth-child(even) td {
        border: none !important;
      }

      &:nth-child(odd)>td:first-child {
        display: none;
      }

      &:nth-last-child(1) td,
      &:nth-last-child(2) td {
        border: none;
      }

      td {
        &:last-child a {
          color: $basic-blue !important;
        }

        &:nth-child(3) .badge {
          margin-left: 5px;
        }

        img {
          opacity: $colors-mute-2;
        }
      }
    }

    tbody tr:hover {
      background: linear-gradient(to right, rgba(0, 249, 214, 0.05) 0%, rgba(91, 93, 250, 0.1) 30%, rgba(0, 0, 0, 0) 100%);
    }

    .table-row-top {
      background: linear-gradient(to right, rgba(0, 249, 214, 0.3) 0%, rgba(91, 93, 250, 0.4) 30%, rgba(0, 0, 0, 0) 100%);

      &:hover {
        background: linear-gradient(to right, rgba(0, 249, 214, 0.35) 0%, rgba(91, 93, 250, 0.45) 30%, rgba(0, 0, 0, 0) 100%);
      }

      td {
        background: transparent !important;
        color: inherit !important;
      }

      img {
        opacity: 1 !important;
      }
    }

    .table-row-dead-project {
      background: $bg-dead;

      &:hover {
        background: $bg-dead;
      }

      td {
        background: transparent !important;
        color: inherit !important;
      }
    }

    .project-logo {
      margin-right: 10px;
    }

    .table_desktop__chart_link {
      display: flex;
      flex-direction: row;
    }

    .table_desktop__chart_link * {
      cursor: pointer;
    }

    .table_desktop__name_link:hover {
      text-decoration: underline !important;
    }

    .MuiTableContainer-root {
      overflow: hidden;
      font-family: inherit !important;
    }

    .Mui-TableBodyCell-DetailPanel {
      padding: 0 !important;
      background-color: $table-panel-background-color !important;
    }

    .Mui-TableBodyCell-DetailPanel>td {
      padding: 0;
    }

    .MuiPaper-root:first-child {
      background-image: inherit;
    }

    .real-tvl-tip {
      color: $basic-blue;
      opacity: $colors-mute-2;
    }



    .text-secondary {
      color: $basic-blue !important;
    }
  }

  .mobile {
    padding: 0;

    .Mui-TableHeadCell-Content div:has(.very-custom) {
      width: 100% !important;
    }

    @media screen and (max-width: 1320px) {

      .Mui-TableHeadCell-Content-Wrapper,
      .Mui-TableHeadCell-Content-Labels {
        width: 100%;
      }
    }

    .table-details .container {
      width: 100% !important;
      margin: 5px !important;
      font-size: small;
    }

    .mob_buttton {
      text-decoration: none;

      .button {
        width: 70% !important;
        max-width: 250px !important;
      }
    }

    .table-details a {
      margin: 0;
      padding: 0;
    }

    .mobile-token-network {
      border-radius: 50%;

      img:first-child {
        margin-right: 2px;
      }
    }
  }

  .table-details {
    background-color: black !important;
  }

  #countdown ul {
    padding-left: 0;

  }
}