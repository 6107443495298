@import "../../dist/css/Variables.scss";

.stats-component {
  .mosaic-container {
    width: 300px;
    height: 200px;
    margin: 0 auto;
    cursor: pointer;

    img {
      height: 50px;
      width: 50px;
    }
  }

  h2,
  h3 {
    width: fit-content;
  }

  h3 {
    color: white;
    font-weight: 300;
    -webkit-text-fill-color: white;
  }

  .first-number {
    background: linear-gradient(to right, #00F9EF 0%, #5B7FFA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  .fit-content {
    width: fit-content;
  }

  @media screen and (max-width: 960px) {

    h2,
    h3,
    h1,
    h4 {
      margin: 0 auto;
    }
  }
}