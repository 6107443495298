@import "../../dist/css/Variables.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.75);
  z-index: 9999;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &__background {
    background: linear-gradient(90deg, #e81cff10, #40c9ff2c) padding-box,
      linear-gradient(#000, #000) padding-box,
      linear-gradient(90deg, $basic-purple, $basic-blue ) border-box !important;
    padding: 40px;
    display: flex;
    z-index: 3;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}