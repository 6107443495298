@import "../../dist/css/Variables.scss";

.submit-page {
  .button {
    margin-right: 0px;
    background: transparent;

    &::after {
      background: rgb(13, 18, 30)
    }
  }

  form {

    input,
    select,
    textarea,
    textarea:focus,
    input:focus,
    select:focus {
      background-color: transparent;
      color: $basic-text-color;
    }

    input {
      color: $basic-text-color;
    }

    textarea::placeholder,
    input::placeholder {
      color: $basic-blue;
      opacity: .25;
    }

    .form-control,
    .form-select {
      border-color: rgba($basic-blue, 0.5);
      --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23e81cff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");

      &:focus {
        border-color: $basic-blue;
        box-shadow: $bg-input;
      }
    }

    select {
      option {
        background: rgb(13, 18, 30);
        color: $basic-text-color;
      }
    }

    .form-label {
      @include secondary-text-gradient;
    }
  }
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23e81cff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>') !important;
}