@import "../../../dist/css/Variables.scss";

.button-wrapper {
  text-decoration: none;
}

.button {
  --border-width: .125em;
  --curve-size: .5em;
  color: #afffff;
  -webkit-text-fill-color: $basic-text-color;
  margin-right: 1em;
  margin-bottom: 1em;
  position: relative;
  isolation: isolate;
  display: inline;
  place-content: center;
  top: -7px;
  padding: .5em 1.5em;
  font-size: 17px;
  border: 0;
  text-transform: uppercase;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, .6);
  text-decoration: none;
  clip-path: polygon(
      /* Top-left */
      0% var(--curve-size),
      var(--curve-size) 0,
      /* top-right */
      100% 0,
      100% calc(100% - var(--curve-size)),
      /* bottom-right 1 */
      calc(100% - var(--curve-size)) 100%,
      /* bottom-right 2 */
      0 100%);
  transition: color 250ms;

  &::after,
  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  &::before {
    background: linear-gradient(-45deg, rgba($basic-orange, $colors-mute-3), rgba($basic-purple, $colors-mute-3), rgba($basic-blue, $colors-mute-3)),
      linear-gradient(-45deg, black, black, black);
    background-size: 300% 300%;
    animation: move-bg7234 5s ease infinite;
    z-index: -2;
  }

  @keyframes move-bg7234 {
    0% {
      background-position: 31% 0%
    }

    50% {
      background-position: 70% 100%
    }

    100% {
      background-position: 31% 0%
    }
  }

  &::after {
    background: $basic-background;
    z-index: -1;
    clip-path: polygon(
        /* Top-left */
        var(--border-width) calc(var(--curve-size) + var(--border-width) * .5),
        calc(var(--curve-size) + var(--border-width) * .5) var(--border-width),
        /* top-right */
        calc(100% - var(--border-width)) var(--border-width),
        calc(100% - var(--border-width)) calc(100% - calc(var(--curve-size) + var(--border-width) * .5)),
        /* bottom-right 1 */
        calc(100% - calc(var(--curve-size) + var(--border-width) * .5)) calc(100% - var(--border-width)),
        /* bottom-right 2 */
        var(--border-width) calc(100% - var(--border-width)));
    transition: clip-path 500ms;
  }

  &:where(:hover, :focus)::after {
    clip-path: polygon(
        /* Top-left */
        calc(100% - var(--border-width)) calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
        calc(100% - var(--border-width)) var(--border-width),
        /* top-right */
        calc(100% - var(--border-width)) var(--border-width),
        calc(100% - var(--border-width)) calc(100% - calc(var(--curve-size) + var(--border-width) * .5)),
        /* bottom-right 1 */
        calc(100% - calc(var(--curve-size) + var(--border-width) * .5)) calc(100% - var(--border-width)),
        /* bottom-right 2 */
        calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)) calc(100% - var(--border-width)));
    transition: 200ms;
  }

  &:where(:hover, :focus) {
    color: #fff;
  }

  svg {
    margin-top: -4px;
  }
}

@media (max-width: 768px) {
  .button {
    font-size: 15px;
  }
}

@media (max-width: 568px) {

  .button {
    font-size: 14px;
  }

}

@media (max-width: 436px) {
  .button {
    font-size: 12px;
  }
}