.custom-tooltip {
  background-color: #757575 !important;
  opacity: 0.8 !important;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 0 10px #ccc;
}

.favorite-project {
  fill: url(#primary-gradient);

  &:hover {
    fill: url(#secondary-gradient);
  }
}