@import "./Variables.scss";
@import "./PercentageColors.scss";

.o-block {
  background: linear-gradient(90deg, #e81cff10, #40c9ff2c) padding-box,
    linear-gradient(#000, #000) padding-box,
    linear-gradient(90deg, transparent, $basic-purple, $basic-blue) border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  transform-origin: right bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  margin-bottom: $spacer * 1.5;
}

h1,
h2,
h3,
h4,
.page h1 a {
  width: fit-content;
  background-clip: text;
  font-weight: bold;
  @include primary-text-gradient;
}

p {
  color: $basic-text-color-p;
}

.main-top-banner {
  max-width: 1290px;
  display: block;
  margin: 0 auto;

  img {
    width: 100%;
  }
}



header,
footer {
  margin: 1rem 0;

  .nav-link {

    &::after {
      color: $basic-blue-end;
    }
  }

  .nav-link:hover {
    &::after {
      color: $basic-orange;
    }
  }

  .navbar-brand {
    position: relative;
    z-index: 1;
    color: #fff;
  }

  .navbar-brand:before {
    content: "Orientier One long long";
    position: absolute;
    left: 0;
    top: 7px;
    -webkit-background-clip: text;
    -webkit-text-stroke: 12px transparent;
    text-align: center;
    filter: blur(25px);
    opacity: 0.4;
    z-index: -1;
    animation: color 6s alternate infinite;
    background: linear-gradient(90deg, $basic-blue, $basic-purple, $basic-blue, $basic-purple, $basic-blue, $basic-purple, $basic-blue, $basic-purple, $basic-blue, $basic-purple);
    background-size: 400% 400%;
    border-radius: 36px;
  }

  .dropdown-menu {
    background: black;

    .dropdown-item {
      @include secondary-text-gradient;
      width: fit-content;
    }

    .dropdown-item:hover {
      @include primary-text-gradient;
    }
  }

  @keyframes color {
    from {
      background-position: 0% 0;
    }

    to {
      background-position: 100% 0;
    }

  }
}

.non-border {
  border: none;
  border-radius: 3px;
}

.secondary-text-gradient {
  @include secondary-text-gradient;
}

.primary-text-gradient {
  @include primary-text-gradient;
}

table,
.table {

  tbody {
    tr:hover {

      td,
      th {
        background: transparent !important;
      }
    }
  }

  tr {

    & th,
    & td,
    &.MuiTableRow-root {
      font-family: inherit !important;
      font-size: inherit !important;
      line-height: inherit !important;
      background-color: transparent;
      color: $basic-text-color !important;
      padding: 7px 10px !important;
      border-bottom: 1px solid black !important;
    }

    th,
    .Mui-TableHeadCell-Content-Labels {
      @include secondary-text-gradient;
      opacity: $colors-mute-2;
    }
  }
}