@import "../../dist/css/Variables.scss";

.faq-page {
  .faq-row-wrapper {
    background-color: transparent;

    .faq-body {
      .faq-row {
        border-bottom: 1px solid black;

        .row-title {
          .row-title-text {
            @include secondary-text-gradient;
          }

          .icon-wrapper {
            @include primary-text-gradient;
          }
        }

        .row-content {
          .row-content-text {
            color: $basic-text-color-p;
          }
        }
      }
    }
  }
}