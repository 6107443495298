@import "../../dist/css/Variables.scss";

.countdown-timer {
  color: #ffffff;
  margin: 0 auto;
  text-align: center;

  #headline {
    width: fit-content;
    margin: 0 auto;
  }

  li {
    @include secondary-text-gradient;
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;

    span {
      display: block;
      font-size: 4.5rem;
    }
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: calc(1.5rem * var(--smaller));
    }

    li {
      font-size: calc(1.125rem * var(--smaller));

      span {
        font-size: calc(3.375rem * var(--smaller));
      }
    }
  }

}