@import "../../../dist/css/Variables.scss";

header,
footer {
  .nav-link {
    @include secondary-text-gradient;
    width: fit-content;
  }

  .nav-link:hover,
  .active {
    @include primary-text-gradient;
  }

  .pffff {
    padding-top: 7px;
  }
}