@import "../../dist/css/Variables.scss";

.page {
  position: relative;
  z-index: 500;
  color: $basic-text-color;

  h1 {
    width: 100%;

    .badge {
      top: -5px;
      position: relative;
      margin-left: 10px;
      background-clip: unset;
      -webkit-text-fill-color: initial;
      font-size: 0.8rem;
    }
  }

  a {
    text-decoration: none;
  }

  .logo {
    width: 100%;
  }

  .network-icon,
  .token-icon {
    width: 28px;
  }

  .stats-table td,
  .txs-table td {
    height: 38px;
    padding: 4px;
  }

  .stats-table td:last-child {
    text-align: right;
  }

  .txs-table {
    table-layout: fixed;
    text-overflow: hidden;
  }

  .MuiPaper-root {
    background-color: transparent !important;
  }

  .token-icon {
    border-radius: 15px;
  }

  .MuiTablePagination-root,
  .MuiToolbar-gutters,
  .MuiTablePagination-root svg {
    background-color: transparent !important;
    color: $basic-text-color;
  }

  .MuiMenu-list li {
    background-color: rgba(0, 0, 0, 0.5);
    color: $basic-text-color;
  }



  table {
    background-color: transparent !important;
    font-size: 0.9rem;

    tbody {
      tr:hover {
        background: linear-gradient(to right, rgba(0, 249, 214, 0.05) 0%, rgba(91, 93, 250, 0.1) 100%);
      }
    }

    tr th:nth-child(2),
    tr th:nth-child(3),
    tr td:nth-child(2),
    tr td:nth-child(3) {
      text-align: center;
    }

    tr td:last-child a {
      color: $basic-blue;
    }

    tr:last-child td {
      border-bottom: none !important;
    }
  }


  .users-table {
    table {

      tr td,
      tr th {
        min-width: 10px !important;
      }

      tr th:nth-child(2) div,
      tr th:nth-child(3) div {
        justify-content: center;
      }

      tr td:nth-child(3),
      tr th:nth-child(3) {
        width: 10px !important;
      }

      tr td:nth-child(1),
      tr th:nth-child(1) {
        width: 150px !important;
      }

      tr td:nth-child(2),
      tr th:nth-child(2) {
        width: 130px !important;
      }
    }
  }

  .txs-table {
    table {

      tr td,
      tr th {
        min-width: 10px !important;
      }

      tr td:nth-child(1),
      tr th:nth-child(1) {
        min-width: 80px !important;
      }

      @media screen and (max-width: 1400px) {

        tr td:nth-child(1),
        tr th:nth-child(1) {
          width: 130px !important;
        }

        tr td:nth-child(2),
        tr th:nth-child(2) {
          width: 60px !important;
        }
      }
    }
  }

  .audits-buttons {
    text-align: left;
  }

  .out-buttons {
    height: 1em;
  }

  @media (min-width: 1200px) {
    .out-buttons {
      float: right;
    }

    .audits-buttons {
      margin-top: 30px;
      text-align: right;
    }
  }

  @media (max-width: 568px) {
    .button {
      margin-top: 1em;
      margin-right: 0.5em;
    }

    .out-buttons {
      margin-bottom: 2em;
    }

    p {
      font-size: 14px;
    }
  }
}

.project-page-right-banner {
  position: absolute;
  right: calc(-1*((100vw - 1320px) / 2 + 160px) / 2);
}

@media screen and (max-width: 1610px) {
  .project-page-right-banner {
    display: none;
  }

}