@import "./../../dist/css/Variables.scss";

.search-box {
  input {
    border-color: #310636;
    opacity: $colors-mute-1;
    color: #18d9f2;

    &::placeholder {
      color: #18d9f2;
      opacity: 0.4;
    }

    &:focus {
      border-color: #b71bcd;
      color: #18d9f2;
    }
  }

  .search-results {
    position: absolute;
    background: rgba(0, 0, 0, .8);
    z-index: 700;
    width: 233px;

    @media screen and (max-width: 750px) {
      width: 90%;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        padding: 5px 15px;
        color: $basic-text-color;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}