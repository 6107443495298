@import "../../../dist/css/Variables.scss";

footer {
  .footer-nav {

    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

  }

  .nav-link,
  h4 {
    text-align: right;
  }

  h4 {
    opacity: $colors-mute-2;
  }

  .nav-link:hover .orientier-icon {
    fill: url(#primary-gradient);
  }

}